@tailwind base;
@tailwind components;
@tailwind utilities;

/* custom css here */

@layer base {

    a {
        @apply hover:text-red-500; 
    }

}